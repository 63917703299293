import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Top from '../views/Top.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'top',
    component: Top
  },
  {
    path: '/page',
    component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue'),
    children: [
      {
        path: '/page/menu',
        name: 'page-menu',
        component: () => import(/* webpackChunkName: "menu" */ '../views/Menu.vue')
      },
      {
        path: '/page/date',
        name: 'page-date',
        component: () => import(/* webpackChunkName: "date" */ '../views/Date.vue')
      },
      {
        path: '/page/profile',
        name: 'page-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
      },
      {
        path: '/page/confirm',
        name: 'page-confirm',
        component: () => import(/* webpackChunkName: "confirm" */ '../views/Confirm.vue')
      },
      {
        path: '/page/accepted',
        name: 'page-accepted',
        component: () => import(/* webpackChunkName: "accepted" */ '../views/Accepted.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/appointment',
  routes
})

export default router
