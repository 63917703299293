import { ActionTree } from 'vuex'
import { AppointmentState, RootState } from '@/pages/appointment/store/types'
import { MenuType } from '@/pages/appointment/types'

const actions: ActionTree<AppointmentState, RootState> = {
  setFirstHopeDay: async ({ commit }, firstHopeDay: Date) => {
    commit('setFirstHopeDay', firstHopeDay)
    return true
  },
  setFirstHopeHour: async ({ commit }, firstHopeHour: string) => {
    commit('setFirstHopeHour', firstHopeHour)
    return true
  },
  setSecondHopeDay: async ({ commit }, secondHopeDay: Date) => {
    commit('setSecondHopeDay', secondHopeDay)
    return true
  },
  setSecondHopeHour: async ({ commit }, secondHopeHour: string) => {
    commit('setSecondHopeHour', secondHopeHour)
    return true
  },
  setThirdHopeDay: async ({ commit }, thirdHopeDay: Date) => {
    commit('setThirdHopeDay', thirdHopeDay)
    return true
  },
  setThirdHopeHour: async ({ commit }, thirdHopeHour: string) => {
    commit('setThirdHopeHour', thirdHopeHour)
    return true
  },
  setContactPossibleHour: async ({ commit }, contactPossibleHour: string) => {
    commit('setContactPossibleHour', contactPossibleHour)
    return true
  },
  setInitialDiagnosis: async ({ commit }, initialDiagnosis: boolean) => {
    commit('setInitialDiagnosis', initialDiagnosis)
    return true
  },
  setDate: async ({ commit }) => {
    commit('setDate')
    return true
  },
  setMenu: async ({ commit }, menu: string) => {
    commit('setMenu', menu)
    return true
  },
  setSelectedMenuIndex: async ({ commit }, selectedMenuIndex: number) => {
    commit('setSelectedMenuIndex', selectedMenuIndex)
    return true
  },
  setName: async ({ commit }, name: string) => {
    commit('setName', name)
    return true
  },
  setKana: async ({ commit }, kana: string) => {
    commit('setKana', kana)
    return true
  },
  setBirthday: async ({ commit }, birthday: Date) => {
    commit('setBirthday', birthday)
    return true
  },
  setSex: async ({ commit }, sex: string) => {
    commit('setSex', sex)
    return true
  },
  setPhone: async ({ commit }, phone: string) => {
    commit('setPhone', phone)
    return true
  },
  setEmail: async ({ commit }, email: string) => {
    commit('setEmail', email)
    return true
  },
  setMedicalCardNumber: async ({ commit }, medicalCardNumber: string) => {
    commit('setMedicalCardNumber', medicalCardNumber)
    return true
  },
  setRequestsAndEtc: async ({ commit }, requestsAndEtc: string) => {
    commit('setRequestsAndEtc', requestsAndEtc)
    return true
  },
  seProfile: async ({ commit }) => {
    commit('seProfile')
    return true
  },
  setConfirm: async ({ commit }) => {
    commit('setConfirm')
    return true
  },
  setAccepted: async ({ commit }) => {
    commit('setAccepted')
    return true
  },
  setMenuList: async ({ commit }, menuList: MenuType[]) => {
    commit('setMenuList', menuList)
    return true
  }
}

export default actions
