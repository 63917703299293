import { GetterTree } from 'vuex'
import { AppointmentState, RootState } from '@/pages/appointment/store/types'

const getters: GetterTree<AppointmentState, RootState> = {
  firstHopeDay: (state: AppointmentState) => {
    return state.firstHopeDay
  },
  firstHopeHour: (state: AppointmentState) => {
    return state.firstHopeHour
  },
  secondHopeDay: (state: AppointmentState) => {
    return state.secondHopeDay
  },
  secondHopeHour: (state: AppointmentState) => {
    return state.secondHopeHour
  },
  thirdHopeDay: (state: AppointmentState) => {
    return state.thirdHopeDay
  },
  thirdHopeHour: (state: AppointmentState) => {
    return state.thirdHopeHour
  },
  contactPossibleHour: (state: AppointmentState) => {
    return state.contactPossibleHour
  },
  initialDiagnosis: (state: AppointmentState) => {
    return state.initialDiagnosis
  },
  pageList: (state: AppointmentState) => {
    return state.pageList
  },
  selectedMenu: (state: AppointmentState) => {
    return state.selectedMenu
  },
  selectedMenuIndex: (state: AppointmentState) => {
    return state.selectedMenuIndex
  },
  name: (state: AppointmentState) => {
    return state.name
  },
  kana: (state: AppointmentState) => {
    return state.kana
  },
  birthday: (state: AppointmentState) => {
    return state.birthday
  },
  age: (state: AppointmentState) => {
    return state.age
  },
  sex: (state: AppointmentState) => {
    return state.sex
  },
  phone: (state: AppointmentState) => {
    return state.phone
  },
  email: (state: AppointmentState) => {
    return state.email
  },
  medicalCardNumber: (state: AppointmentState) => {
    return state.medicalCardNumber
  },
  requestsAndEtc: (state: AppointmentState) => {
    return state.requestsAndEtc
  },
  complete: (state: AppointmentState) => {
    return state.complete
  },
  menuList: (state: AppointmentState) => {
    return state.menuList
  }
}

export default getters
