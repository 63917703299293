import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import { createHttpClient } from '@/lib/axios-helper'
import { AxiosStatic } from 'axios'

Vue.config.productionTip = false
Vue.prototype.$debug = true

// axios
Vue.prototype.$http = createHttpClient({ debug: Vue.prototype.$debug })
declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosStatic;
  }
}

// Vue Router 遷移時にトップへスクロール
router.afterEach(() => {
  window.scroll(0, 0)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
