import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

const addDebugInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      console.log('Request', config.url, config)
      return config
    },
    (error: AxiosError) => {
      console.error('Response', error)
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      console.log('Response', response.config.url, response.status, response)
      return response
    },
    (error: AxiosError) => {
      console.error('Response', error)
      return Promise.reject(error)
    }
  )
}

export const createHttpClient = ({ debug = false }) => {
  const instance = axios.create({
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    timeout: 10000
  })
  if (debug) {
    addDebugInterceptor(instance)
  }
  return instance
}
