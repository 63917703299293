import { Module } from 'vuex'
import { AppointmentState, RootState } from '@/pages/appointment/store/types'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state: AppointmentState = {
  firstHopeDay: null,
  firstHopeHour: null,
  secondHopeDay: null,
  secondHopeHour: null,
  thirdHopeDay: null,
  thirdHopeHour: null,
  contactPossibleHour: null,
  initialDiagnosis: false,
  pageList: {
    menu: { name: 'メニュー', routerName: 'page-menu', inputted: false, icon: 'mdi-format-list-bulleted ' },
    date: { name: '日時', routerName: 'page-date', inputted: false, icon: 'mdi-calendar-blank-outline ' },
    profile: { name: '患者情報', routerName: 'page-profile', inputted: false, icon: 'mdi-pencil-outline ' },
    confirm: { name: '内容確認', routerName: 'page-confirm', inputted: false, icon: 'mdi-check' },
    accepted: { name: '仮予約完了', routerName: 'page-accepted', inputted: false, icon: 'mdi-thumb-up-outline ' }
  },
  selectedMenu: '',
  selectedMenuIndex: null,
  name: null,
  kana: null,
  birthday: null,
  age: 'ー',
  sex: null,
  phone: null,
  email: null,
  medicalCardNumber: null,
  requestsAndEtc: null,
  complete: false,
  menuList: []
}

export const appointments: Module<AppointmentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
