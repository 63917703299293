






















































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import TitleComp from '@/pages/appointment/components/Title.vue'

export default Vue.extend({
  name: 'Top',
  components: {
    TitleComp
  },
  computed: {
    ...mapGetters('appointments', [
      'complete'
    ])
  },
  methods: {
    ...mapActions('appointments', [
      'setInitialDiagnosis'
    ]),
    clickNextBtn (initialDiagnosis: boolean): void {
      if (!this.complete) {
        this.setInitialDiagnosis(initialDiagnosis)
        this.$router.push({ name: 'page-menu' })
      }
    }
  }
})
