import { MutationTree } from 'vuex'
import { AppointmentState } from '@/pages/appointment/store/types'
import { MenuType } from '@/pages/appointment/types'
import moment from 'moment'

const mutations: MutationTree<AppointmentState> = {
  setFirstHopeDay: (state, firstHopeDay: Date) => {
    state.firstHopeDay = firstHopeDay
  },
  setFirstHopeHour: (state, firstHopeHour: string) => {
    state.firstHopeHour = firstHopeHour
  },
  setSecondHopeDay: (state, secondHopeDay: Date) => {
    state.secondHopeDay = secondHopeDay
  },
  setSecondHopeHour: (state, secondHopeHour: string) => {
    state.secondHopeHour = secondHopeHour
  },
  setThirdHopeDay: (state, thirdHopeDay: Date) => {
    state.thirdHopeDay = thirdHopeDay
  },
  setThirdHopeHour: (state, thirdHopeHour: string) => {
    state.thirdHopeHour = thirdHopeHour
  },
  setContactPossibleHour: (state, contactPossibleHour: string) => {
    state.contactPossibleHour = contactPossibleHour
  },
  setInitialDiagnosis: (state, initialDiagnosis: boolean) => {
    state.initialDiagnosis = initialDiagnosis
  },
  setDate: (state) => {
    state.pageList.date.inputted = true
  },
  setMenu: (state, menu: string) => {
    state.selectedMenu = menu
    state.pageList.menu.inputted = true
  },
  setSelectedMenuIndex: (state, selectedMenuIndex: number) => {
    state.selectedMenuIndex = selectedMenuIndex
  },
  setName: (state, name: string) => {
    state.name = name
  },
  setKana: (state, kana: string) => {
    state.kana = kana
  },
  setBirthday: (state, birthday: Date) => {
    state.birthday = birthday
    state.age = moment().diff(moment(birthday), 'years').toString()
  },
  setSex: (state, sex: string) => {
    state.sex = sex
  },
  setPhone: (state, phone: string) => {
    state.phone = phone
  },
  setEmail: (state, email: string) => {
    state.email = email
  },
  setMedicalCardNumber: (state, medicalCardNumber: string) => {
    state.medicalCardNumber = medicalCardNumber
  },
  setRequestsAndEtc: (state, requestsAndEtc: string) => {
    state.requestsAndEtc = requestsAndEtc
  },
  seProfile: (state) => {
    state.pageList.profile.inputted = true
  },
  setConfirm: (state) => {
    state.pageList.confirm.inputted = true
  },
  setAccepted: (state) => {
    state.pageList.accepted.inputted = true
    state.complete = true
  },
  setMenuList: (state, menuList: MenuType[]) => {
    state.menuList = menuList
  }
}

export default mutations
